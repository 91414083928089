import React from 'react';
import { useVisualizacao } from './VisualizacaoContext';

import { MdRemoveRedEye } from 'react-icons/md';
import Popup from '@atlaskit/popup';
import Button from '@atlaskit/button/standard-button';
import Tooltip from '@atlaskit/tooltip';

import ModalVisualizacao from './components/ModalVisualizacao';
import SelectVisualizacao from './components/SelectVisualizacao';

export const VisualizacaoContent: React.FC = () => {
  const { isOpenPopup, setIsOpenPopup, isOpenModal } = useVisualizacao();
  return (
    <>
      {isOpenModal ? (
        <ModalVisualizacao />
      ) : (
        <Popup
          isOpen={isOpenPopup}
          onClose={() => setIsOpenPopup(false)}
          placement="bottom-end"
          content={() => <SelectVisualizacao />}
          trigger={(triggerProps) => (
            <Button
              {...triggerProps}
              title="Visualização"
              onClick={() => setIsOpenPopup(!isOpenPopup)}
            >
              <Tooltip position="right" content="CTRL + ALT + V">
                <MdRemoveRedEye />
              </Tooltip>
            </Button>
          )}
        />
      )}
    </>
  );
};
