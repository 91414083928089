import React from 'react';

import { useVisualizacao } from '../../VisualizacaoContext';

import { Loading } from '../Loading';
import { Container, SelectInput, Span } from './styles';
import { useTableViewManager } from '~/context/tableViewManager';

const SelectVisualizacao: React.FC = () => {
  const {
    setIsOpenModal,
    optionsViews,
    loadingViews,
    setLoadingViews,
    applyingVisualization,
    handleViewSelect,
    viewSelect,
    hadleViewRefetch,
    setLimit,
    setSelectedOptionView,
  } = useVisualizacao();

  const { onChangeCodCardSelected, onChangeViewSelected } =
    useTableViewManager();

  return (
    <Container>
      {loadingViews ? (
        <Loading />
      ) : (
        <>
          <div className="input">
            <label htmlFor="single-select">Visualização</label>
            <SelectInput
              value={viewSelect.value || -1}
              onChange={(e) => {
                const viewSelectFilter = optionsViews.filter(
                  (item) => item.value === Number(e.target.value),
                )[0];

                handleViewSelect(viewSelectFilter);

                setLimit(viewSelectFilter.qtd_registro);
                setLoadingViews(true);
                applyingVisualization(Number(e.target.value));

                onChangeCodCardSelected(null);
                onChangeViewSelected(null);

                setTimeout(() => {
                  hadleViewRefetch();
                }, 400);
                setLoadingViews(false);
              }}
            >
              {optionsViews.map((item) => {
                return (
                  <option
                    className={item.value === -1 ? 'padrao' : 'demais'}
                    value={item.value}
                  >
                    {item.label}
                  </option>
                );
              })}
            </SelectInput>
          </div>
          <Span
            onClick={() => {
              if (viewSelect.value === -1) {
                setSelectedOptionView(null);
              } else {
                setSelectedOptionView({
                  label: viewSelect.label,
                  value: viewSelect.value,
                  qtd_colunas_fixadas: viewSelect.qtd_colunas_fixadas,
                  qtd_registro: viewSelect.qtd_registro,
                });
              }

              setIsOpenModal(true);
            }}
          >
            Customizar
          </Span>
        </>
      )}
    </Container>
  );
};

export default SelectVisualizacao;
