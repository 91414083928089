import React from 'react';
import { useContext } from 'react';
import { ClipLoader } from 'react-spinners';
import { ThemeContext } from 'styled-components';
import { ConteinerLoading } from './styles';

export const Loading: React.FC = () => {
  const { colors } = useContext(ThemeContext);

  return (
    <ConteinerLoading>
      <ClipLoader color={colors.primary} />
    </ConteinerLoading>
  );
};
