import React, { useEffect, useState } from 'react';
import { CreatableSelect } from '@atlaskit/select';
import { useVisualizacao } from '../../VisualizacaoContext';
import { ConfirmButton, CancelButton, ClearButton } from '~/components/Buttons';
import { FormActionsContainer, ButtonsContainer } from './styles';
import { OptionsViewProps } from '../../types';

export const FormActions: React.FC = () => {
  const [inputvalue, setinputvalue] = useState<string>('');
  const [optionsWithoutPadrao, setOptionsWithoutPadrao] = useState<
    OptionsViewProps[]
  >([]);
  const {
    selectedOptionView,
    optionsViews,
    onCreateView,
    handleChangeView,
    handleSubmit,
    handleClear,
    handleDelete,
    isUpdate,
  } = useVisualizacao();

  useEffect(() => {
    if (optionsViews.length > 0) {
      setOptionsWithoutPadrao(
        optionsViews.filter((option) => option.value !== -1),
      );
    }
  }, [optionsViews]);

  return (
    <FormActionsContainer>
      <CreatableSelect
        formatCreateLabel={(userInput: string) =>
          `TOQUE AQUI para criar a visualização '${userInput.toUpperCase()}'`
        }
        className="view-select"
        id="view-select"
        // eslint-disable-next-line no-shadow
        onInputChange={(value: string) => setinputvalue(value.toUpperCase())}
        inputValue={inputvalue}
        placeholder="Digite um nome para Cadastro ou Selecione uma Visualização"
        noOptionsMessage={() => 'Nenhum Registro Encontrado'}
        value={selectedOptionView}
        options={optionsWithoutPadrao}
        onChange={handleChangeView}
        onCreateOption={onCreateView}
        inLine
      />
      <ButtonsContainer>
        <ConfirmButton onClick={handleSubmit} disabled={!selectedOptionView}>
          Salvar
        </ConfirmButton>
        <ClearButton
          onClick={handleClear}
          disabled={!selectedOptionView}
          style={{
            marginLeft: '0px',
          }}
        >
          Limpar
        </ClearButton>
        <CancelButton onClick={handleDelete} disabled={!isUpdate}>
          Excluir
        </CancelButton>
      </ButtonsContainer>
    </FormActionsContainer>
  );
};
