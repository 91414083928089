import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 350px;
  height: 100px;
  color: var(--ds-text-lowEmphasis, var(--ds-text-lowEmphasis, #6b778c));
  gap: 10px;
  justify-content: center;
  align-items: center;
  .input {
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: auto 1fr;
    gap: 10px;
    grid-template-rows: 1fr;
  }
`;

export const SelectInput = styled.select`
  display: grid;
  width: 250px;
  border-radius: 3px;
  border-color: var(--ds-text-lowEmphasis, var(--ds-text-lowEmphasis, #6b778c));
  color: var(--ds-text-lowEmphasis, var(--ds-text-lowEmphasis, #6b778c));
  padding: 10px;
  option {
    height: 2rem !important;
    padding: 10px;
  }
  .padrao {
    color: rgb(51, 153, 255);
    padding: 10px;
  }
`;
export const Span = styled.span`
  display: flex;
  width: 100%;
  background: none;
  border: none;
  justify-content: flex-end;
  color: rgb(51, 153, 255);
  cursor: pointer;
  font-size: 12pt;
  margin-right: 20px;

  &:hover {
    text-decoration: underline;
  }
`;
