import React from 'react';

import { VisualizacaoContextProvider } from './VisualizacaoContext';
import { VisualizacaoContent } from './VisualizacaoContent';

import { VisualizacaoProps } from './types';

const Visualizacao: React.FC<VisualizacaoProps> = ({
  codTela,
  handleColumns,
  limit,
  setLimit,
  isUpdateLimit,
  setIsUpdateLimit,
  refetch,
  setView,
  sortCampo,
  setSortCampos,
  showTable,
  openPopupView,
}) => {
  return (
    <VisualizacaoContextProvider
      codTela={codTela}
      handleColumns={handleColumns}
      limit={limit}
      setLimit={setLimit}
      isUpdateLimit={isUpdateLimit}
      setIsUpdateLimit={setIsUpdateLimit}
      refetch={refetch}
      setView={setView}
      sortCampo={sortCampo}
      setSortCampos={setSortCampos}
      showTable={showTable}
      openPopupView={openPopupView}
    >
      <VisualizacaoContent />
    </VisualizacaoContextProvider>
  );
};

export default Visualizacao;
