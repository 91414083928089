import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import api from '~/services/api';

import {
  OptionsProps,
  OptionsViewProps,
  ProviderVisualizacaoProps,
  VisualizacaoContextData,
} from './types';
import { useQueryClient } from 'react-query';
import { CacheLastSearchProps } from '../Search/types';
import { useTableViewManager } from '~/context/tableViewManager';
import { useSearch } from '../Search/SearchContext';

export const VisualizacaoContext = createContext({} as VisualizacaoContextData);

export function VisualizacaoContextProvider({
  codTela,
  handleColumns,
  children,
  limit,
  setLimit,
  isUpdateLimit,
  setIsUpdateLimit,
  refetch,
  setView,
  sortCampo,
  setSortCampos,
  showTable,
  openPopupView,
}: ProviderVisualizacaoProps): JSX.Element {
  const queryClient = useQueryClient();
  const [isChangeView, setIsChangeView] = useState<boolean>(false);
  const [isOpenPopup, setIsOpenPopup] = useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [loadingViews, setLoadingViews] = useState<boolean>(false);
  const [optionsViews, setOptionsViews] = useState<OptionsViewProps[]>([]);
  const [viewSelect, setViewSelect] = useState<OptionsViewProps>({
    value: -1,
    label: 'PADRÃO',
    qtd_colunas_fixadas: 0,
    qtd_registro: 10,
  });
  const [selectedOptionView, setSelectedOptionView] =
    useState<OptionsViewProps | null>(null);

  const [fixedColumns, setFixedColumns] = useState<number>(0);

  const [loadingModal, setLoadingModal] = useState<boolean>(false);
  const [optionAvailable, setOptionAvailable] = useState<OptionsProps[]>([]);
  const [optionSelected, setOptionSelected] = useState<OptionsProps[]>([]);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [init, setInit] = useState<boolean>(false);

  const {
    codVisao,
    codCardSelected,
    viewCardSelected,
    updateGrid,
    onChangeCodVisao,
    onChangeQtdColunasFixadasNaGrid,
  } = useTableViewManager();

  const { View } = useSearch();

  const delay = (ms: number): Promise<void> => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  useEffect(() => {
    applyingVisualization(undefined);
  }, [codTela]);

  useEffect(() => {
    if (openPopupView) {
      setIsOpenPopup(openPopupView);
    }
  }, [openPopupView]);

  useEffect(() => {
    async function UpdateRegisterPage() {
      if (
        selectedOptionView?.value &&
        isUpdate &&
        isUpdateLimit &&
        limit !== selectedOptionView.qtd_registro
      ) {
        await api.put('visualizacao/registro', {
          codTela,
          codView: selectedOptionView?.value,
          qtd_registro: limit,
        });
        setIsUpdateLimit(false);
      }
    }
    UpdateRegisterPage();
  }, [limit]);

  useEffect(() => {
    async function UpdateOrder() {
      if (selectedOptionView?.value && isUpdate) {
        await api.put('/visualizacao/tipo-ordem-campo', {
          codTela,
          codView: selectedOptionView?.value,
          sortCampo,
        });
      }
    }
    UpdateOrder();
  }, [sortCampo]);

  const handleViewSelect = useCallback(
    (value: OptionsViewProps) => {
      setViewSelect(value);
      setView(value.label);
    },
    [setView],
  );

  const getViews = useCallback(async () => {
    setLoadingViews(true);
    const res = await api.get(`/visualizacao/view/${codTela}`);
    const { success, data } = res.data;
    let options = [];
    if (success) {
      options = data.map((item: any) => {
        setFixedColumns(item.qtd_col_fixada);
        onChangeQtdColunasFixadasNaGrid(item.qtd_col_fixada);
        return {
          label: item.des_visao,
          value: item.cod_visao,
          qtd_colunas_fixadas: item.qtd_col_fixada,
          qtd_registro: item.qtd_registro,
        };
      });
      options.unshift({
        label: 'PADRÃO',
        value: -1,
        qtd_colunas_fixadas: 0,
        qtd_registro: 10,
      });
      setOptionsViews(options);
    }
    setLoadingViews(false);

    return options;
  }, [codTela]);

  const getFields = useCallback(
    async (codView: number | undefined) => {
      setLoadingModal(true);

      try {
        const { data } = await api.get('visualizacao/campos', {
          params: {
            codTela,
            codView,
            cod_card: codCardSelected,
            cod_view_card: codVisao || null,
          },
        });

        const dataSortAvailable = data.available.map(
          (item: any, index: number) => ({
            order: index + 1,
            cod_campo: item.cod_campo,
            des_campo: item.des_campo || item.field,
          }),
        );
        const dataSortSelected = data.selected.map(
          (item: any, index: number) => ({
            order: item.num_ordem || index,
            cod_campo: item.cod_campo,
            des_campo: item.des_campo,
          }),
        );
        setOptionAvailable(dataSortAvailable);
        setOptionSelected(dataSortSelected);
      } finally {
        setLoadingModal(false);
      }
    },
    [codTela, codVisao, codCardSelected],
  );

  const applyingVisualizationDefault = useCallback(
    (data: any) => {
      const cacheLastSearch: any = queryClient.getQueryData(
        `cacheLastSearch_${codTela}`,
      );
      const orderBy = cacheLastSearch?.orderBy || undefined;

      const dataSort = data.available.map((item: any) => {
        const field = item.nome_bd.split('.')[1];
        let orderAvaiable = -1;

        if (orderBy) {
          const orderBySplit = orderBy.find((item2: any) =>
            item2.includes(`${field}`),
          );
          if (orderBySplit) {
            const [, direction] = orderBySplit.split(' ');
            orderAvaiable =
              direction === 'asc' ? 0 : direction === 'desc' ? 1 : -1;
          }
        }

        queryClient.removeQueries(`cacheLastSearch_${codTela}`);

        // 174 = Rel. log de carga
        // usando retorno do if para inserir toltip quando for tela relatorio log de carga
        if (codTela === 174) {
          return {
            field,
            nome_bd: item.nome_bd,
            order: orderAvaiable,
            sortingOrder: ['asc', 'desc'],
            codCampo: item.cod_campo,
            headerName: `${item.des_campo}`,
            visibility: true,
            disableReorder: true,
            width: item.val_largura < 80 ? 300 : item.val_largura,
            tipo_agregador_tot: item.tipo_agregador_tot ?? -1,
            cod_tipo_dados: item.cod_tipo_dados,
            listagem_info: item.listagem_info,
            tipo_expressao: item.tipo_expressao,
            renderCell: (paramsItem: any) => {
              return (
                <>
                  <span
                    style={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                    title={paramsItem.value}
                  >
                    {paramsItem.value}
                  </span>
                </>
              );
            },
          };
        }
        return {
          field,
          nome_bd: item.nome_bd,
          order: orderAvaiable,
          sortingOrder: ['asc', 'desc'],
          codCampo: item.cod_campo,
          headerName: `${item.des_campo}`,
          visibility: true,
          disableReorder: true,
          width: item.val_largura < 80 ? 300 : item.val_largura,
          tipo_agregador_tot: item.tipo_agregador_tot ?? -1,
          cod_tipo_dados: item.cod_tipo_dados,
          listagem_info: item.listagem_info,
          tipo_expressao: item.tipo_expressao,
        };
      });
      const sort = dataSort.map((item: any) => {
        return {
          field: item.field,
          sort: item.order === -1 ? null : item.order === 0 ? 'asc' : 'desc',
        };
      });
      handleColumns(dataSort);
      setSortCampos(sort);
    },
    [codTela, handleColumns, setSortCampos, queryClient],
  );

  const applyingVisualizationModified = useCallback(
    (data: any) => {
      const dataSort = data.selected.map((item: any) => ({
        field: item.nome_bd.split('.')[1],
        nome_bd: item.nome_bd,
        order: item.tipo_ordenacao,
        sortingOrder: ['asc', 'desc'],
        codCampo: item.cod_campo,
        headerName: item.des_campo,
        visibility: true,
        disableReorder: true,
        cellClassName: 'fixed',
        width: item.val_largura < 80 ? 300 : item.val_largura,
        tipo_agregador_tot: item.tipo_agregador_tot ?? -1,
      }));
      const sort = dataSort.map((item: any) => {
        return {
          field: item.field,
          // eslint-disable-next-line no-nested-ternary
          sort: item.order === -1 ? null : item.order === 0 ? 'asc' : 'desc',
        };
      });
      const cacheLastSearch: CacheLastSearchProps | undefined =
        queryClient.getQueryData(`cacheLastSearch_${codTela}`);
      if (cacheLastSearch?.shouldReturnSearch) {
        queryClient.removeQueries(`cacheLastSearch_${codTela}`);
      }
      handleColumns(dataSort);
      setSortCampos(sort);
    },
    [handleColumns, setSortCampos],
  );

  const hadleViewRefetch = useCallback(async () => {
    await refetch();
  }, [refetch]);

  const handleselectedOptionView = useCallback(
    async (view: any, codview: any, optionView: any) => {
      const option = await optionView.find(
        (item: any) => item.value === codview,
      );
      if (!option && view?.lastView) {
        setIsUpdate(!!view.data?.lastView);

        setSelectedOptionView({
          label: view.lastView.des_visao,
          value: view.lastView.cod_visao,
          qtd_colunas_fixadas: view.data.lastView.qtd_col_fixada,
          qtd_registro: view.lastView.qtd_registro,
        });
        setLimit(view.lastView.qtd_registro || 10);
      } else {
        setIsUpdate(!!option);
        setFixedColumns(option?.qtd_colunas_fixadas || 0);
        onChangeQtdColunasFixadasNaGrid(option?.qtd_colunas_fixadas || 0);

        setLimit(option?.qtd_registro || 10);
        setIsUpdateLimit(true);
        setSelectedOptionView(option || null);
      }
    },
    [setLimit, setIsUpdateLimit],
  );

  const handleClear = useCallback(
    async (noClearCodView?: boolean) => {
      await getFields(noClearCodView ? selectedOptionView?.value : undefined);
      setSelectedOptionView(null);
      setFixedColumns(0);
      onChangeQtdColunasFixadasNaGrid(0);
      setIsUpdate(false);
      setIsChangeView(false);
    },
    [getFields, selectedOptionView],
  );

  const getViewTelaUsuario = useCallback(
    async (optionView) => {
      const { data } = await api.get(`/visualizacao/atualizada/${codTela}`);
      if (data.lastView && !codCardSelected) {
        const viewSelected = optionView.filter(
          (item: any) => item?.value === data?.lastView?.cod_visao,
        )[0];
        if (!init) handleViewSelect(viewSelected);
      }

      return data;
    },
    [codCardSelected, codTela, handleViewSelect, init],
  );

  const changeLabelH2 = useCallback((label: string) => {
    const element = document.getElementById('ClickedContainerFilter');
    if (element) {
      const h2 = element.getElementsByTagName('h2')[0];
      if (h2) {
        const { childNodes } = h2;
        let lastTextNode: Text | null = null;

        childNodes.forEach((node) => {
          if (node.nodeType === Node.TEXT_NODE) lastTextNode = node as Text;
        });

        if (lastTextNode) (lastTextNode as Text).textContent = label;
      }
    }
  }, []);

  const applyingVisualization = useCallback(
    async (codView: number | undefined) => {
      const views = await getViews();

      const view = views.filter((v: any) => v.label === View)[0];
      const viewTelaUsuario = await getViewTelaUsuario(views);

      let codview: any;

      if (typeof codView === 'undefined' && !codCardSelected) {
        if (Object.keys(viewTelaUsuario).length > 1) {
          if (viewTelaUsuario?.lastView?.cod_visao) {
            codview = viewTelaUsuario?.lastView?.cod_visao;
          }
        } else if (view) {
          codview = view.value;
        } else codview = null;
      } else if (typeof codView === 'undefined' && codCardSelected) {
        if (codVisao === 0) {
          codview = null;
        } else {
          codview = codVisao ?? view?.value;
        }
      } else {
        codview = codView || viewTelaUsuario?.lastView?.cod_visao;
      }

      if (codCardSelected) {
        const viewSelected = views.find((v: any) => v.value === codview);
        if (viewSelected) {
          setViewSelect(viewSelected);
          changeLabelH2(viewSelected.label);
        }
      } else if (codView && viewCardSelected) {
        if (viewCardSelected.value === codview) {
          setViewSelect(viewCardSelected);
          changeLabelH2(viewCardSelected.label);
        } else {
          const visao = views.find((v: any) => v.value === codview);
          if (visao) {
            setViewSelect(visao);
            changeLabelH2(visao.label);
          }
        }
      }

      const { data } = await api.get('visualizacao/campos', {
        params: {
          codTela,
          codView: codview,
          isUpdateAccessDate: codView !== null,
          cod_card: codCardSelected,
          cod_view_card: codVisao || null,
        },
      });
      if (data.selected.length > 0) {
        applyingVisualizationModified(data);
      } else {
        applyingVisualizationDefault(data);
      }
      const dataSortAvailable = data.available.map(
        (item: any, index: number) => ({
          order: index + 1,
          cod_campo: item.cod_campo,
          des_campo: item.des_campo || item.field,
        }),
      );
      const dataSortSelected = data.selected.map(
        (item: any, index: number) => ({
          order: item.num_ordem || index,
          cod_campo: item.cod_campo,
          des_campo: item.des_campo,
        }),
      );
      setOptionAvailable(
        dataSortAvailable.sort((a: any, b: any) =>
          a.des_campo.localeCompare(b.des_campo),
        ),
      );
      setOptionSelected(dataSortSelected);
      handleselectedOptionView(data, codview, views);

      setInit(true);
    },
    [
      codTela,
      getViewTelaUsuario,
      applyingVisualizationDefault,
      applyingVisualizationModified,
      getViews,
      handleselectedOptionView,
      codVisao,
      codCardSelected,
      View,
      viewCardSelected,
      changeLabelH2,
    ],
  );

  const applyCustomVisualization = useCallback(async () => {
    if (updateGrid) {
      if (viewCardSelected) {
        const { value } = viewCardSelected;
        onChangeCodVisao(value);
        await applyingVisualization(value);
      }
    } else if (!viewCardSelected && codCardSelected) {
      const { data } = await api.get(
        `/tela-card/visao/${codCardSelected}/${codTela}`,
      );

      await delay(200);

      if (data.success) {
        const result = data.data;

        if (result.cod_visao) {
          onChangeCodVisao(result.cod_visao);
          await applyingVisualization(result.cod_visao);
          return;
        }

        onChangeCodVisao(null);
        await applyingVisualization(undefined);
      }
    }
  }, [
    codCardSelected,
    codTela,
    onChangeCodVisao,
    updateGrid,
    viewCardSelected,
  ]);

  useEffect(() => {
    applyCustomVisualization();
  }, [applyCustomVisualization]);

  const onCreateView = useCallback(
    async (text: string) => {
      setIsChangeView(true);
      setIsUpdate(false);
      setSelectedOptionView({
        label: text.toUpperCase(),
        value: -1,
        qtd_colunas_fixadas: fixedColumns,
        qtd_registro: limit || 10,
      });
    },
    [fixedColumns, limit],
  );

  const handleChangeView = useCallback(
    async (value: OptionsViewProps) => {
      setIsUpdate(true);
      setFixedColumns(value?.qtd_colunas_fixadas || 0);
      onChangeQtdColunasFixadasNaGrid(value?.qtd_colunas_fixadas || 0);
      setSelectedOptionView(value);
      await getFields(value.value);
    },
    [getFields],
  );

  const handleDelete = useCallback(async () => {
    if (selectedOptionView?.value !== undefined) {
      const result = await api.delete(
        `/visualizacao/${Number(selectedOptionView?.value)}`,
      );
      setIsUpdate(false);
      setIsOpenModal(false);
      setIsOpenPopup(false);
      setView('PADRÃO');
      setViewSelect({
        value: -1,
        label: 'PADRÃO',
        qtd_colunas_fixadas: 0,
        qtd_registro: 10,
      });
      setIsChangeView(false);
      setSelectedOptionView(null);
      toast.success(result.data.message);
      await applyingVisualization(undefined);
    }
  }, [selectedOptionView, applyingVisualization, setView, setViewSelect]);

  const handleSubmit = useCallback(async () => {
    try {
      if (optionSelected.length < 1) {
        toast.warning(
          'Inclua ao menos um campo para a criação da visualização',
        );
        return;
      }
      if (!isUpdate) {
        const data = {
          codTela,
          des_visao: selectedOptionView?.label,
          qtd_col_fixada: selectedOptionView?.qtd_colunas_fixadas,
          qtd_registro: limit || 10,
          optionSelected,
        };
        const result = await api.post(`/visualizacao/`, data);
        setIsUpdate(true);
        setSelectedOptionView({
          label: result.data.data.des_visao,
          value: result.data.data.cod_visao,
          qtd_colunas_fixadas: fixedColumns,
          qtd_registro: limit || 10,
        });
        setIsOpenModal(false);
        setIsOpenPopup(false);
        toast.success(result.data.message);
        setView(result.data.data.des_visao);
        handleViewSelect({
          label: result.data.data.des_visao,
          value: result.data.data.cod_visao,
          qtd_colunas_fixadas: fixedColumns,
          qtd_registro: limit || 10,
        });

        await applyingVisualization(result.data.data.cod_visao);
      } else {
        const data = {
          codTela,
          cod_visao: selectedOptionView?.value,
          des_visao: selectedOptionView?.label,
          qtd_col_fixada: fixedColumns,
          qtd_registro: limit || 10,
          optionSelected,
        };
        const result = await api.put(`/visualizacao/`, data);

        if (selectedOptionView) {
          handleViewSelect({
            label: selectedOptionView?.label,
            value: selectedOptionView?.value,
            qtd_colunas_fixadas: fixedColumns,
            qtd_registro: limit || 10,
          });

          await applyingVisualization(selectedOptionView?.value);
        }

        setIsOpenModal(false);
        setIsOpenPopup(false);
        toast.success(result.data.message);
      }
    } catch (error: any) {
      console.log(error);
    }
  }, [
    codTela,
    isUpdate,
    selectedOptionView,
    fixedColumns,
    optionSelected,
    applyingVisualization,
    limit,
    handleViewSelect,
    setView,
  ]);

  return (
    <VisualizacaoContext.Provider
      value={{
        isChangeView,
        setIsChangeView,
        isOpenPopup,
        setIsOpenPopup,
        optionsViews,
        selectedOptionView,
        isOpenModal,
        setIsOpenModal,
        loadingViews,
        setLoadingViews,
        loadingModal,
        optionAvailable,
        setOptionAvailable,
        optionSelected,
        setOptionSelected,
        fixedColumns,
        setFixedColumns,
        onCreateView,
        handleChangeView,
        handleDelete,
        handleSubmit,
        handleClear,
        applyingVisualization,
        handleViewSelect,
        viewSelect,
        hadleViewRefetch,
        setLimit,
        setSelectedOptionView,
        showTable,
        isUpdate,
      }}
    >
      {children}
    </VisualizacaoContext.Provider>
  );
}

export const useVisualizacao = (): VisualizacaoContextData => {
  return useContext(VisualizacaoContext);
};
