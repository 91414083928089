import styled from 'styled-components';

export const FormActionsContainer = styled.div`
  display: grid;
  height: 80px;
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  @media only screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;
